// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery") // needed? yup, for font picker at least.
require("jquery-ui/ui/widget")
require("jquery-ui/ui/widgets/sortable")
require("simplemde")
require("custom/stripe")
// require("custom/modernizr")

import CodeMirror from 'codemirror';

import 'codemirror/mode/css/css.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import modernizr from 'modernizr';
// module.exports = {
//   options: ["setClasses"],
//   "feature-detects": [
//     "test/css/flexbox",
//     "test/es6/promises",
//     "test/serviceworker"
//   ]
// };
// modernizr

// import 'codemirror-liquid-multiplex';

 require("custom/codemirror-liquid")


import SimpleMDE from 'simplemde';

window.Rails = Rails

// ffs rails, broken ujs out of the box?
// import $ from 'jquery';
// global.$ = jQuery;

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
global.$ = require("jquery")


import 'bootstrap'
import 'data-confirm-modal'
import 'data-confirm-modal'
import '../scss/application.scss'

$(document).on("turbolinks:load", () => {

  $('.inputtypes-datetime-local .datetimehtml5 input').removeAttr('disabled');
  $('.inputtypes-datetime-local .datetimeselects select').attr('disabled', 'disabled');

 // or liquid.
 if (document.getElementById("account_css")){
  CodeMirror.fromTextArea(document.getElementById('account_css'), {
    mode: 'css'
  });
 }


  $(".trix-content a[href^='http://']").attr("target","_blank");
  $(".trix-content a[href^='https://']").attr("target","_blank");
    // var simplemde = new SimpleMDE({ element: $(".simplemde")[0] });
   // var simplemde = new SimpleMDE({ element: $(".simplemde")[1] });

   $('textarea.simplemde').each(function() {
    var simplemde = new SimpleMDE({
        element: this,
    });
    simplemde.render();
    })

 $('[data-toggle="tooltip"]').tooltip()
 $('[data-toggle="popover"]').popover()

 $(".sortable").sortable({
   handle: '.handle',
   update: function(e, ui) {
     Rails.ajax({
       url: $(this).data("url"),
       type: "PATCH",
       data: $(this).sortable('serialize'),
     });
   }
 });

 $(document).on('change', '#lesson_live', function() {
   console.log(this.checked);
   if (this.checked){
    $('.live_at_boxes').addClass('invisible')  ;
   }else{
     $('.live_at_boxes').removeClass('invisible');
   }
 });



 $("#blocks").sortable({
   handle: '.handle',
   update: function(e, ui) {
     Rails.ajax({
       url: $(this).data("url"),
       type: "PATCH",
       data: $(this).sortable('serialize'),
     });
   }
 });

 $("#sections").sortable({
   handle: '.handle',
   update: function(e, ui) {
     Rails.ajax({
       url: $(this).data("url"),
       type: "PATCH",
       data: $(this).sortable('serialize'),
     });
   }
 });

})




// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
